body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body, html {
    width: 100%;
    height: 100%
}

input::-ms-clear, input::-ms-reveal {
    display: none
}

*, :after, :before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

@-ms-viewport {
    width: device-width
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block
}

body {
    margin: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-variant: tabular-nums;
    line-height: 1.5;
    background-color: #fff;
    font-feature-settings: "tnum"
}

[tabindex="-1"]:focus {
    outline: none !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5em;
    color: rgba(0, 0, 0, .85);
    font-weight: 500
}

p {
    margin-top: 0;
    margin-bottom: 1em
}

abbr[data-original-title], abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    border-bottom: 0;
    cursor: help
}

address {
    margin-bottom: 1em;
    font-style: normal;
    line-height: inherit
}

input[type=number], input[type=password], input[type=text], textarea {
    -webkit-appearance: none
}

dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1em
}

ol ol, ol ul, ul ol, ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 500
}

dd {
    margin-bottom: .5em;
    margin-left: 0
}

blockquote {
    margin: 0 0 1em
}

dfn {
    font-style: italic
}

b, strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub, sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color .3s;
    -webkit-text-decoration-skip: objects
}

a:hover {
    color: #40a9ff
}

a:active {
    color: #096dd9
}

a:active, a:hover {
    text-decoration: none;
    outline: 0
}

a[disabled] {
    color: rgba(0, 0, 0, .25);
    cursor: not-allowed;
    pointer-events: none
}

code, kbd, pre, samp {
    font-size: 1em;
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace
}

pre {
    margin-top: 0;
    margin-bottom: 1em;
    overflow: auto
}

figure {
    margin: 0 0 1em
}

img {
    vertical-align: middle;
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

[role=button], a, area, button, input:not([type=range]), label, select, summary, textarea {
    touch-action: manipulation
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75em;
    padding-bottom: .3em;
    color: rgba(0, 0, 0, .45);
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

button, input, optgroup, select, textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit
}

button, input {
    overflow: visible
}

button, select {
    text-transform: none
}

[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: .5em;
    padding: 0;
    color: inherit;
    font-size: 1.5em;
    line-height: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item
}

template {
    display: none
}

[hidden] {
    display: none !important
}

mark {
    padding: .2em;
    background-color: #feffe6
}

::selection {
    color: #fff;
    background: #1890ff
}

.clearfix {
    zoom: 1
}

.clearfix:after, .clearfix:before {
    content: "";
    display: table
}

.clearfix:after {
    clear: both
}

.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.anticon > * {
    line-height: 1
}

.anticon svg {
    display: inline-block
}

.anticon:before {
    display: none
}

.anticon .anticon-icon {
    display: block
}

.anticon[tabindex] {
    cursor: pointer
}

.anticon-spin:before {
    display: inline-block;
    animation: loadingCircle 1s linear infinite
}

.anticon-spin {
    display: inline-block;
    animation: loadingCircle 1s linear infinite
}

.fade-appear, .fade-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.fade-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.fade-appear.fade-appear-active, .fade-enter.fade-enter-active {
    animation-name: antFadeIn;
    animation-play-state: running
}

.fade-leave.fade-leave-active {
    animation-name: antFadeOut;
    animation-play-state: running;
    pointer-events: none
}

.fade-appear, .fade-enter {
    opacity: 0;
    animation-timing-function: linear
}

.fade-leave {
    animation-timing-function: linear
}

@keyframes antFadeIn {
    0% {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes antFadeOut {
    0% {
        opacity: 1
    }
    to {
        opacity: 0
    }
}

.move-up-appear, .move-up-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.move-up-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.move-up-appear.move-up-appear-active, .move-up-enter.move-up-enter-active {
    animation-name: antMoveUpIn;
    animation-play-state: running
}

.move-up-leave.move-up-leave-active {
    animation-name: antMoveUpOut;
    animation-play-state: running;
    pointer-events: none
}

.move-up-appear, .move-up-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.move-up-leave {
    animation-timing-function: cubic-bezier(.6, .04, .98, .34)
}

.move-down-appear, .move-down-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.move-down-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.move-down-appear.move-down-appear-active, .move-down-enter.move-down-enter-active {
    animation-name: antMoveDownIn;
    animation-play-state: running
}

.move-down-leave.move-down-leave-active {
    animation-name: antMoveDownOut;
    animation-play-state: running;
    pointer-events: none
}

.move-down-appear, .move-down-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.move-down-leave {
    animation-timing-function: cubic-bezier(.6, .04, .98, .34)
}

.move-left-appear, .move-left-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.move-left-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.move-left-appear.move-left-appear-active, .move-left-enter.move-left-enter-active {
    animation-name: antMoveLeftIn;
    animation-play-state: running
}

.move-left-leave.move-left-leave-active {
    animation-name: antMoveLeftOut;
    animation-play-state: running;
    pointer-events: none
}

.move-left-appear, .move-left-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.move-left-leave {
    animation-timing-function: cubic-bezier(.6, .04, .98, .34)
}

.move-right-appear, .move-right-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.move-right-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.move-right-appear.move-right-appear-active, .move-right-enter.move-right-enter-active {
    animation-name: antMoveRightIn;
    animation-play-state: running
}

.move-right-leave.move-right-leave-active {
    animation-name: antMoveRightOut;
    animation-play-state: running;
    pointer-events: none
}

.move-right-appear, .move-right-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.move-right-leave {
    animation-timing-function: cubic-bezier(.6, .04, .98, .34)
}

@keyframes antMoveDownIn {
    0% {
        transform: translateY(100%);
        transform-origin: 0 0;
        opacity: 0
    }
    to {
        transform: translateY(0);
        transform-origin: 0 0;
        opacity: 1
    }
}

@keyframes antMoveDownOut {
    0% {
        transform: translateY(0);
        transform-origin: 0 0;
        opacity: 1
    }
    to {
        transform: translateY(100%);
        transform-origin: 0 0;
        opacity: 0
    }
}

@keyframes antMoveLeftIn {
    0% {
        transform: translateX(-100%);
        transform-origin: 0 0;
        opacity: 0
    }
    to {
        transform: translateX(0);
        transform-origin: 0 0;
        opacity: 1
    }
}

@keyframes antMoveLeftOut {
    0% {
        transform: translateX(0);
        transform-origin: 0 0;
        opacity: 1
    }
    to {
        transform: translateX(-100%);
        transform-origin: 0 0;
        opacity: 0
    }
}

@keyframes antMoveRightIn {
    0% {
        transform: translateX(100%);
        transform-origin: 0 0;
        opacity: 0
    }
    to {
        transform: translateX(0);
        transform-origin: 0 0;
        opacity: 1
    }
}

@keyframes antMoveRightOut {
    0% {
        transform: translateX(0);
        transform-origin: 0 0;
        opacity: 1
    }
    to {
        transform: translateX(100%);
        transform-origin: 0 0;
        opacity: 0
    }
}

@keyframes antMoveUpIn {
    0% {
        transform: translateY(-100%);
        transform-origin: 0 0;
        opacity: 0
    }
    to {
        transform: translateY(0);
        transform-origin: 0 0;
        opacity: 1
    }
}

@keyframes antMoveUpOut {
    0% {
        transform: translateY(0);
        transform-origin: 0 0;
        opacity: 1
    }
    to {
        transform: translateY(-100%);
        transform-origin: 0 0;
        opacity: 0
    }
}

@keyframes loadingCircle {
    to {
        transform: rotate(1turn)
    }
}

[ant-click-animating-without-extra-node=true], [ant-click-animating=true] {
    position: relative
}

.ant-click-animating-node, [ant-click-animating-without-extra-node=true]:after {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    display: block;
    border: 0 solid #1890ff;
    border-radius: inherit;
    opacity: .2;
    animation: fadeEffect 2s cubic-bezier(.08, .82, .17, 1), waveEffect .4s cubic-bezier(.08, .82, .17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
    content: ""
}

@keyframes waveEffect {
    to {
        top: -6px;
        right: -6px;
        bottom: -6px;
        left: -6px;
        border-width: 6px
    }
}

@keyframes fadeEffect {
    to {
        opacity: 0
    }
}

.slide-up-appear, .slide-up-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.slide-up-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.slide-up-appear.slide-up-appear-active, .slide-up-enter.slide-up-enter-active {
    animation-name: antSlideUpIn;
    animation-play-state: running
}

.slide-up-leave.slide-up-leave-active {
    animation-name: antSlideUpOut;
    animation-play-state: running;
    pointer-events: none
}

.slide-up-appear, .slide-up-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(.23, 1, .32, 1)
}

.slide-up-leave {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06)
}

.slide-down-appear, .slide-down-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.slide-down-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.slide-down-appear.slide-down-appear-active, .slide-down-enter.slide-down-enter-active {
    animation-name: antSlideDownIn;
    animation-play-state: running
}

.slide-down-leave.slide-down-leave-active {
    animation-name: antSlideDownOut;
    animation-play-state: running;
    pointer-events: none
}

.slide-down-appear, .slide-down-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(.23, 1, .32, 1)
}

.slide-down-leave {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06)
}

.slide-left-appear, .slide-left-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.slide-left-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.slide-left-appear.slide-left-appear-active, .slide-left-enter.slide-left-enter-active {
    animation-name: antSlideLeftIn;
    animation-play-state: running
}

.slide-left-leave.slide-left-leave-active {
    animation-name: antSlideLeftOut;
    animation-play-state: running;
    pointer-events: none
}

.slide-left-appear, .slide-left-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(.23, 1, .32, 1)
}

.slide-left-leave {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06)
}

.slide-right-appear, .slide-right-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.slide-right-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.slide-right-appear.slide-right-appear-active, .slide-right-enter.slide-right-enter-active {
    animation-name: antSlideRightIn;
    animation-play-state: running
}

.slide-right-leave.slide-right-leave-active {
    animation-name: antSlideRightOut;
    animation-play-state: running;
    pointer-events: none
}

.slide-right-appear, .slide-right-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(.23, 1, .32, 1)
}

.slide-right-leave {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06)
}

@keyframes antSlideUpIn {
    0% {
        transform: scaleY(.8);
        transform-origin: 0 0;
        opacity: 0
    }
    to {
        transform: scaleY(1);
        transform-origin: 0 0;
        opacity: 1
    }
}

@keyframes antSlideUpOut {
    0% {
        transform: scaleY(1);
        transform-origin: 0 0;
        opacity: 1
    }
    to {
        transform: scaleY(.8);
        transform-origin: 0 0;
        opacity: 0
    }
}

@keyframes antSlideDownIn {
    0% {
        transform: scaleY(.8);
        transform-origin: 100% 100%;
        opacity: 0
    }
    to {
        transform: scaleY(1);
        transform-origin: 100% 100%;
        opacity: 1
    }
}

@keyframes antSlideDownOut {
    0% {
        transform: scaleY(1);
        transform-origin: 100% 100%;
        opacity: 1
    }
    to {
        transform: scaleY(.8);
        transform-origin: 100% 100%;
        opacity: 0
    }
}

@keyframes antSlideLeftIn {
    0% {
        transform: scaleX(.8);
        transform-origin: 0 0;
        opacity: 0
    }
    to {
        transform: scaleX(1);
        transform-origin: 0 0;
        opacity: 1
    }
}

@keyframes antSlideLeftOut {
    0% {
        transform: scaleX(1);
        transform-origin: 0 0;
        opacity: 1
    }
    to {
        transform: scaleX(.8);
        transform-origin: 0 0;
        opacity: 0
    }
}

@keyframes antSlideRightIn {
    0% {
        transform: scaleX(.8);
        transform-origin: 100% 0;
        opacity: 0
    }
    to {
        transform: scaleX(1);
        transform-origin: 100% 0;
        opacity: 1
    }
}

@keyframes antSlideRightOut {
    0% {
        transform: scaleX(1);
        transform-origin: 100% 0;
        opacity: 1
    }
    to {
        transform: scaleX(.8);
        transform-origin: 100% 0;
        opacity: 0
    }
}

.swing-appear, .swing-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.swing-appear.swing-appear-active, .swing-enter.swing-enter-active {
    animation-name: antSwingIn;
    animation-play-state: running
}

@keyframes antSwingIn {
    0%, to {
        transform: translateX(0)
    }
    20% {
        transform: translateX(-10px)
    }
    40% {
        transform: translateX(10px)
    }
    60% {
        transform: translateX(-5px)
    }
    80% {
        transform: translateX(5px)
    }
}

.zoom-appear, .zoom-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-appear.zoom-appear-active, .zoom-enter.zoom-enter-active {
    animation-name: antZoomIn;
    animation-play-state: running
}

.zoom-leave.zoom-leave-active {
    animation-name: antZoomOut;
    animation-play-state: running;
    pointer-events: none
}

.zoom-appear, .zoom-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.zoom-leave {
    animation-timing-function: cubic-bezier(.78, .14, .15, .86)
}

.zoom-big-appear, .zoom-big-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-big-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-big-appear.zoom-big-appear-active, .zoom-big-enter.zoom-big-enter-active {
    animation-name: antZoomBigIn;
    animation-play-state: running
}

.zoom-big-leave.zoom-big-leave-active {
    animation-name: antZoomBigOut;
    animation-play-state: running;
    pointer-events: none
}

.zoom-big-appear, .zoom-big-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.zoom-big-leave {
    animation-timing-function: cubic-bezier(.78, .14, .15, .86)
}

.zoom-big-fast-appear, .zoom-big-fast-enter {
    animation-duration: .1s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-big-fast-leave {
    animation-duration: .1s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-big-fast-appear.zoom-big-fast-appear-active, .zoom-big-fast-enter.zoom-big-fast-enter-active {
    animation-name: antZoomBigIn;
    animation-play-state: running
}

.zoom-big-fast-leave.zoom-big-fast-leave-active {
    animation-name: antZoomBigOut;
    animation-play-state: running;
    pointer-events: none
}

.zoom-big-fast-appear, .zoom-big-fast-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.zoom-big-fast-leave {
    animation-timing-function: cubic-bezier(.78, .14, .15, .86)
}

.zoom-up-appear, .zoom-up-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-up-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-up-appear.zoom-up-appear-active, .zoom-up-enter.zoom-up-enter-active {
    animation-name: antZoomUpIn;
    animation-play-state: running
}

.zoom-up-leave.zoom-up-leave-active {
    animation-name: antZoomUpOut;
    animation-play-state: running;
    pointer-events: none
}

.zoom-up-appear, .zoom-up-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.zoom-up-leave {
    animation-timing-function: cubic-bezier(.78, .14, .15, .86)
}

.zoom-down-appear, .zoom-down-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-down-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-down-appear.zoom-down-appear-active, .zoom-down-enter.zoom-down-enter-active {
    animation-name: antZoomDownIn;
    animation-play-state: running
}

.zoom-down-leave.zoom-down-leave-active {
    animation-name: antZoomDownOut;
    animation-play-state: running;
    pointer-events: none
}

.zoom-down-appear, .zoom-down-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.zoom-down-leave {
    animation-timing-function: cubic-bezier(.78, .14, .15, .86)
}

.zoom-left-appear, .zoom-left-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-left-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-left-appear.zoom-left-appear-active, .zoom-left-enter.zoom-left-enter-active {
    animation-name: antZoomLeftIn;
    animation-play-state: running
}

.zoom-left-leave.zoom-left-leave-active {
    animation-name: antZoomLeftOut;
    animation-play-state: running;
    pointer-events: none
}

.zoom-left-appear, .zoom-left-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.zoom-left-leave {
    animation-timing-function: cubic-bezier(.78, .14, .15, .86)
}

.zoom-right-appear, .zoom-right-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-right-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-right-appear.zoom-right-appear-active, .zoom-right-enter.zoom-right-enter-active {
    animation-name: antZoomRightIn;
    animation-play-state: running
}

.zoom-right-leave.zoom-right-leave-active {
    animation-name: antZoomRightOut;
    animation-play-state: running;
    pointer-events: none
}

.zoom-right-appear, .zoom-right-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.zoom-right-leave {
    animation-timing-function: cubic-bezier(.78, .14, .15, .86)
}

@keyframes antZoomIn {
    0% {
        transform: scale(.2);
        opacity: 0
    }
    to {
        transform: scale(1);
        opacity: 1
    }
}

@keyframes antZoomOut {
    0% {
        transform: scale(1)
    }
    to {
        transform: scale(.2);
        opacity: 0
    }
}

@keyframes antZoomBigIn {
    0% {
        transform: scale(.8);
        opacity: 0
    }
    to {
        transform: scale(1);
        opacity: 1
    }
}

@keyframes antZoomBigOut {
    0% {
        transform: scale(1)
    }
    to {
        transform: scale(.8);
        opacity: 0
    }
}

@keyframes antZoomUpIn {
    0% {
        transform: scale(.8);
        transform-origin: 50% 0;
        opacity: 0
    }
    to {
        transform: scale(1);
        transform-origin: 50% 0
    }
}

@keyframes antZoomUpOut {
    0% {
        transform: scale(1);
        transform-origin: 50% 0
    }
    to {
        transform: scale(.8);
        transform-origin: 50% 0;
        opacity: 0
    }
}

@keyframes antZoomLeftIn {
    0% {
        transform: scale(.8);
        transform-origin: 0 50%;
        opacity: 0
    }
    to {
        transform: scale(1);
        transform-origin: 0 50%
    }
}

@keyframes antZoomLeftOut {
    0% {
        transform: scale(1);
        transform-origin: 0 50%
    }
    to {
        transform: scale(.8);
        transform-origin: 0 50%;
        opacity: 0
    }
}

@keyframes antZoomRightIn {
    0% {
        transform: scale(.8);
        transform-origin: 100% 50%;
        opacity: 0
    }
    to {
        transform: scale(1);
        transform-origin: 100% 50%
    }
}

@keyframes antZoomRightOut {
    0% {
        transform: scale(1);
        transform-origin: 100% 50%
    }
    to {
        transform: scale(.8);
        transform-origin: 100% 50%;
        opacity: 0
    }
}

@keyframes antZoomDownIn {
    0% {
        transform: scale(.8);
        transform-origin: 50% 100%;
        opacity: 0
    }
    to {
        transform: scale(1);
        transform-origin: 50% 100%
    }
}

@keyframes antZoomDownOut {
    0% {
        transform: scale(1);
        transform-origin: 50% 100%
    }
    to {
        transform: scale(.8);
        transform-origin: 50% 100%;
        opacity: 0
    }
}

.ant-motion-collapse {
    overflow: hidden
}

.ant-motion-collapse-active {
    transition: height .15s cubic-bezier(.645, .045, .355, 1), opacity .15s cubic-bezier(.645, .045, .355, 1) !important
}

#root, body, html {
    height: 100%
}

.colorWeak {
    -webkit-filter: invert(80%);
    filter: invert(80%)
}

.ant-layout {
    min-height: 100vh
}

canvas {
    display: block
}

body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.globalSpin {
    width: 100%;
    margin: 40px 0 !important
}

ol, ul {
    list-style: none
}

@media (max-width: 480px) {
    .ant-table {
        width: 100%;
        overflow-x: auto
    }

    .ant-table-tbody > tr > td, .ant-table-tbody > tr > th, .ant-table-thead > tr > td, .ant-table-thead > tr > th {
        white-space: pre
    }

    .ant-table-tbody > tr > td > span, .ant-table-tbody > tr > th > span, .ant-table-thead > tr > td > span, .ant-table-thead > tr > th > span {
        display: block
    }
}
