.layout {
    height: 100vh;
    width: 100vw;
    display: flex;
    min-height: 100vh;
    background: #f0f2f5;
    flex-direction: column;
    flex:1;
    overflow: hidden;
}

.layout, .layout * {
    box-sizing: border-box;
}

.layout.layout_has_sider {
    flex-direction: row;
}

.sider {
    position: relative;
    /*min-width: 0;*/
    /*background: #001529;*/
    transition: all 0.2s;
    display: flex;
    flex-direction: row;
    width: 265px;
    box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1); /* 添加右侧阴影 */
    background-color: #ffffff;
    overflow: hidden;
    z-index: 2;
}

.sider .menu1 {
    /*width: 64px;*/
    flex: 0 0 64px;
    background-color: #282c34;
}

.sider .menu2 {
    /*flex: auto;*/
    width: 201px;
    transition: all 0.2s;
    padding: 10px;
    overflow: auto;
}

.content {
    flex: 1;
    min-height: 0;
    overflow: auto;
    padding: 12px;
}

.header {
    height: 60px;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    line-height: 60px;
    background-color: #ffffff;
    box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    z-index: 1;
}

.logo{
    text-align: center;
    height: 62px;
    line-height: 62px;
}
.logo img{
    width: 36px;
    height: 36px;
}
.menu{
    display: flex;
    justify-items: center;
    flex-direction: column;
    align-items: center;
}

.menu_item{
    display: flex;
    flex-direction: column;
    color: #ffffff;
    width: 54px;
    height: 54px;
    align-items: center;
    justify-content:center;
    margin-bottom: 12px;
    border-radius: 5px;
    cursor: pointer;
}

.menu_item :first-child{
    margin-top: 2px;
}

.menu_item.selected{
    background-color: #208cff;
}

.title{
    height: 38px;
    font-size: 18px;
    text-align: center;
    line-height: 38px;
}

.menu2 .menu{
    display: block;
    margin-top: 10px;
}

.menu_item2,.submenutitle{
    display: block;
    flex-direction: row;
    color: #000000d9;
    /*height: 46px;*/
    width: 100%;
    margin-bottom: 6px;
    border-radius: 5px;
    cursor: pointer;
    padding: 14px 18px;
    transition: color 0.5s, background-color 0.5s;
}
/*#1890ff;*/
/*#000000d9*/

.menu_item2:hover,.menu_item2.selected,.submenutitle:hover{
    background-color: #e9f4ff;
    color: #1890ff;
}

.trigger{
    padding: 0 24px;
    font-size: 18px;
    /*line-height: 64px;*/
    cursor: pointer;
    transition: color 0.3s;
    align-self: stretch;
    display: flex;
    align-items: center;
}

.trigger:hover {
    color: #1890ff;
}

.submenu{
    display: block;
    width: 100%;
    margin-bottom: 6px;
}
.hidden{
    display: none;
}

.submenutitle{
    display: flex;
    align-items: center;
    transition: border-color .3s, background .3s, padding .1s cubic-bezier(.215,.61,.355,1);
    background-color: #00000008;
}
.title_content{
    flex: auto;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}
